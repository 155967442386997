
export class DropdownLists {
    public static jsonRoles(): string {
        const roles = {
            "optionList": 
            [
                "Administration",
                "Management",
                "Research", 
                "Technician", 
                "Other"
            ], 
            "required": false
        };
      return JSON.stringify(roles);
    }
    public static jsonSectors(): string {
        const sectors = {
            "optionList": 
            [
                "Public", 
                "Private"
            ], 
            "required": false
        };
      return JSON.stringify(sectors);
    }

}


    