import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-c52f12a4")
const _hoisted_1 = {
  key: 0,
  class: "progress is-small is-primary",
  max: "100"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "section" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_modal = _resolveComponent("v-modal")!
  const _component_v_nav_bar = _resolveComponent("v-nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_toast = _resolveComponent("v-toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("progress", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_modal, {
        isActive: _ctx.modalContent !== ''
      }, {
        default: _withCtx(() => [
          (_ctx.modalData !== '')
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalContent), {
                key: 0,
                modalData: _ctx.modalData
              }, null, 8, ["modalData"]))
            : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalContent), { key: 1 }))
        ]),
        _: 1
      }, 8, ["isActive"]),
      _createVNode(_component_v_nav_bar),
      _createElementVNode("section", _hoisted_3, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_v_toast)
    ])
  ], 64))
}