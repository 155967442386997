
import { defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
  name: "VSensitiveInput",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      type: ref("password"),
    };
  },
  methods: {
    toggle(): void {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
  },
});
