import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: _normalizeClass(["button is-primary", { 'is-loading': _ctx.loading }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
        }, _toDisplayString(_ctx.submitTitle), 3),
        (_ctx.cancelTitle !== undefined)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "button is-light",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
            }, _toDisplayString(_ctx.cancelTitle), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}