import { State } from "@/store";
import { Store } from "vuex";

export interface ISignUp_EulaRequest {
  readonly b_eula: boolean;
}

export class SignUp_EulaRequest implements ISignUp_EulaRequest {
  public readonly b_eula: boolean;

  public constructor(data: ISignUp_EulaRequest) {
    this.b_eula = data.b_eula;
  }

  public validate(store: Store<State>): boolean {
    store.dispatch("clearErrors", ["b_eula"]);

    const errors = new Map<string, string>();

    if (!this.b_eula) {
      errors.set("b_eula", "Agreement with the EULA is required.");
    }

    store.dispatch("pushErrors", errors);

    return errors.size === 0;
  }
}
