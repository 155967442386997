import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field has-addons" }
const _hoisted_2 = { class: "control is-expanded" }
const _hoisted_3 = ["type", "value"]
const _hoisted_4 = { class: "control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        class: "input",
        type: _ctx.type,
        value: _ctx.value,
        disabled: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        class: "button is-light",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
      }, _toDisplayString(_ctx.type === "password" ? "Show" : "Hide"), 1)
    ])
  ]))
}