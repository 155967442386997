
import { computed, defineComponent, ref } from "@vue/runtime-core";
import { useStore } from "@/store";

export default defineComponent({
  name: "VToast",
  setup() {
    const store = useStore();

    return {
      isActive: ref(computed(() => store.state.toastClearTimeout !== -1)),
      isFadeOut: ref(computed(() => store.state.toastFadeTimeout === -1)),
      toast: ref(computed(() => store.state.toast)),
    };
  },
});
