
import { useStore } from "@/store";
import { computed, defineComponent, ref } from "vue";
import VSensitiveInput from "@/components/form/VSensitiveInput.vue";
import VSubmit from "@/components/form/VSubmit.vue";
import { Toast } from "@/types";

export default defineComponent({
  name: "VViewApiKey",
  components: {
    VSensitiveInput,
    VSubmit,
  },
  setup() {
    const store = useStore();
    const apiKey = computed(() => store.state.apiKey);

    return {
      apiKey: ref(apiKey),
      loading: ref(computed(() => apiKey.value.id === "")),
    };
  },
  methods: {
    cancel(): void {
      this.$store.dispatch("clearModal");
    },
    copy(): void {
      navigator.clipboard.writeText(this.apiKey.value);
      Toast.success(this.$store, "API key successfully copied.");
    },
  },
});
