import { State } from "@/store";
import { Store } from "vuex";

export enum ToastType {
  ERROR = "is-danger",
  LINK = "is-link",
  PRIMARY = "is-primary",
  SUCCESS = "is-success",
  WARNING = "is-warning",
  INFO = "is-info",
}

export class Toast {
  public message: string;
  public type: ToastType;

  private constructor(message: string, type: ToastType) {
    this.message = message;
    this.type = type;
  }

  public static error(store: Store<State>, message: string): void {
    store.dispatch("setToast", new Toast(message, ToastType.ERROR));
  }

  public static info(store: Store<State>, message: string): void {
    store.dispatch("setToast", new Toast(message, ToastType.INFO));
  }

  public static link(store: Store<State>, message: string): void {
    store.dispatch("setToast", new Toast(message, ToastType.LINK));
  }

  public static primary(store: Store<State>, message: string): void {
    store.dispatch("setToast", new Toast(message, ToastType.PRIMARY));
  }

  public static success(store: Store<State>, message: string): void {
    store.dispatch("setToast", new Toast(message, ToastType.SUCCESS));
  }

  public static warning(store: Store<State>, message: string): void {
    store.dispatch("setToast", new Toast(message, ToastType.WARNING));
  }
}
