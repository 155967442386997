import { State } from "@/store";
import { Store } from "vuex";
import { SignUpRequest, SignUp_CustomerRequest } from "@/types";

export interface ISignUpConfirmRequest {
  readonly verificationCode: string;
  readonly customerData: SignUp_CustomerRequest;
  readonly signUpData: SignUpRequest;
}

export class SignUpConfirmRequest implements ISignUpConfirmRequest {
  public readonly verificationCode: string;
  public readonly customerData: SignUp_CustomerRequest;
  public readonly signUpData: SignUpRequest;

  public constructor(data: ISignUpConfirmRequest) {
    this.verificationCode = data.verificationCode;
    this.customerData = data.customerData;
    if (data.signUpData) {
      this.signUpData = data.signUpData;
    } else {
      this.signUpData = new SignUpRequest({
        name: "",
        emailAddress: "",
        password: "",
      });
    }
  }

  public validate(store: Store<State>): boolean {
    store.dispatch("clearErrors", ["verificationCode", "customerData"]);

    const errors = new Map<string, string>();

    if (this.verificationCode === "") {
      errors.set("verificationCode", "A verification code is required");
    }

    if (this.customerData === null) {
      errors.set("customerData", "Customer Data is required");
    }

    store.dispatch("pushErrors", errors);
    return errors.size === 0;
  }
}
