import { State } from "@/store";
import { Store } from "vuex";

export interface IContactUsRequest {
  readonly name: string;
  readonly emailAddress: string;
  readonly message: string;
  readonly recaptchaChallengeToken: string;
}

export class ContactUsRequest implements IContactUsRequest {
  public readonly emailAddress: string;
  public readonly name: string;
  public readonly message: string;
  public readonly recaptchaChallengeToken: string;

  public constructor(data: IContactUsRequest) {
    this.emailAddress = data.emailAddress;
    this.name = data.name;
    this.message = data.message;
    this.recaptchaChallengeToken = data.recaptchaChallengeToken;
  }

  public validate(store: Store<State>): boolean {
    store.dispatch("clearErrors", ["name", "emailAddress", "message"]);

    const errors = new Map<string, string>();

    if (this.name === "") {
      errors.set("name", "A name is required");
    }

    if (this.emailAddress === "") {
      errors.set("emailAddress", "An email address is required");
    }

    if (this.message === "") {
      errors.set("message", "A message is required");
    }

    store.dispatch("pushErrors", errors);

    return errors.size === 0;
  }
}
