export interface IApiKey {
  readonly id: string;
  readonly name: string;
  readonly value: string;
}

export function newApiKey(): IApiKey {
  return {
    id: "",
    name: "",
    value: "",
  };
}
