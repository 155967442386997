import { State } from "@/store";
import { Store } from "vuex";

export interface IChangePasswordRequest {
  readonly oldPassword: string;
  readonly newPassword: string;
}

export class ChangePasswordRequest implements IChangePasswordRequest {
  public readonly oldPassword: string;
  public readonly newPassword: string;

  public constructor(data: IChangePasswordRequest) {
    this.oldPassword = data.oldPassword;
    this.newPassword = data.newPassword;
  }

  public validate(store: Store<State>): boolean {
    store.dispatch("clearErrors", ["oldPassword", "newPassword"]);

    const errors = new Map<string, string>();

    if (this.oldPassword === "") {
      errors.set("oldPassword", "An old is required");
    }

    if (this.newPassword === "") {
      errors.set("newPassword", "A new password is required");
    }

    if (this.newPassword.length < 16) {
      errors.set("newPassword", "New password must be at least 16 characters long.");
    }

    store.dispatch("pushErrors", errors);

    return errors.size === 0;
  }
}
