import { State } from "@/store";
import { Store } from "vuex";

export interface ISignInRequest {
  readonly emailAddress: string;
  readonly password: string;
  readonly customerId: string;
  readonly productCode: string;
  readonly subscriberCode: string;
}

export class SignInRequest implements ISignInRequest {
  public readonly emailAddress: string;
  public readonly password: string;
  public readonly customerId: string;
  public readonly productCode: string;
  public readonly subscriberCode: string;

  public constructor(data: ISignInRequest) {
    this.emailAddress = data.emailAddress;
    this.password = data.password;
    this.customerId = data.customerId;
    this.productCode = data.productCode;
    this.subscriberCode = data.subscriberCode;
  }

  public validate(store: Store<State>): boolean {
    store.dispatch("clearErrors", ["emailAddress", "password"]);

    const errors = new Map<string, string>();

    if (this.emailAddress === "") {
      errors.set("emailAddress", "An email address is required");
    }

    if (this.password === "") {
      errors.set("password", "A password is required");
    }

    store.dispatch("pushErrors", errors);

    return errors.size === 0;
  }
}
