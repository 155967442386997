
import { defineComponent, ref } from "@vue/runtime-core";
import { SignUpRequest } from "@/types";
import VInput from "@/components/form/VInput.vue";
import VSubmit from "@/components/form/VSubmit.vue";

export default defineComponent({
  name: "VSignUp",
  components: {
    VInput,
    VSubmit,
  },
  setup() {
    return {
      name: ref(""),
      emailAddress: ref(""),
      password: ref(""),
    };
  },
  methods: {
    cancel(): void {
      this.$store.dispatch("clearModal");
    },
    error(key: string) {
      return this.$store.getters.error(key);
    },
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    signIn(): void {
      this.$store.dispatch("setModal", "VSignIn");
    },
    async signUp(): Promise<void> {
      const data = new SignUpRequest({
        name: this.name,
        emailAddress: this.emailAddress,
        password: this.password,
      });

      if (!data.validate(this.$store)) {
        return;
      }

      await this.$store.dispatch("signUp", data);
    },
  },
});
