
import { Key } from "@/resources";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "VSubmit",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    submitTitle: {
      type: String,
      required: true,
    },
    cancelTitle: {
      type: String,
    },
  },
  created(): void {
    window.addEventListener("keyup", this.customKeySubmit);
  },
  beforeUnmount(): void {
    window.removeEventListener("keyup", this.customKeySubmit);
  },
  methods: {
    customKeySubmit(e: KeyboardEvent) {
      switch (e.key) {
        //case Key.ENTER:
        //  this.submit();
        //  break;
        case Key.ESCAPE:
          this.cancel();
          break;
        default:
        // Fallthrough.
      }
    },
    cancel(): void {
      this.$emit("cancel");
    },
    submit(): void {
      this.$emit("submit");
    },
  },
});
