
import { defineComponent } from "@vue/runtime-core";


export default defineComponent({
  name: "VDropdown",
  props: {
    title: {
      type: String,
      required: true,
    },
    btn_title: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    options: {
      type: String,  // JSON!
      required: true,
    },
    error: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    showDropdown():void {
      this.$el.querySelector('#myDropdown')?.classList.toggle("show");
    }, 
    createDropdown():void {
      let str = '';
      const decode = JSON.parse(this.options);
      // console.log(decode)
      decode.optionList.forEach(function(option:string){
        str += '<a "#">' + option + '</a>';
        });
      this.$el.querySelector('#myDropdown')!.innerHTML = str;
    }, 
    selectItem(event:any) {
      this.$emit("update:value", (event.target.innerText))
      //this.$el.querySelector('#dropBtn')!.innerHTML = this.title + ": " + event.target.innerText;
      this.$el.querySelector('#dropBtn')!.innerHTML = event.target.innerText; 
      //this.$el.querySelector('#selected')!.innerHTML = event.target.innerText;
      this.showDropdown();
    }, 
  },
  mounted(){
    this.createDropdown()
 },
});
