import { State } from "@/store";
import { Store } from "vuex";

export interface IForgotPasswordRequest {
  readonly emailAddress: string;
}

export class ForgotPasswordRequest implements IForgotPasswordRequest {
  public readonly emailAddress: string;

  public constructor(data: IForgotPasswordRequest) {
    this.emailAddress = data.emailAddress;
  }

  public validate(store: Store<State>): boolean {
    store.dispatch("clearErrors", ["emailAddress"]);

    const errors = new Map<string, string>();

    if (this.emailAddress === "") {
      errors.set("emailAddress", "An email address is required");
    }

    store.dispatch("pushErrors", errors);

    return errors.size === 0;
  }
}
