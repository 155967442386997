
import { defineComponent, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import VInput from "@/components/form/VInput.vue";
import VSubmit from "@/components/form/VSubmit.vue";
import { SignInRequest } from "@/types";

export default defineComponent({
  name: "VSignIn",
  components: {
    VInput,
    VSubmit,
  },
  setup() {
    const router = useRouter();

    const params = new Map([
      ["status", router.currentRoute.value.query.status?.toString()],
      ["customerId", router.currentRoute.value.query.customerId?.toString()],
      ["productCode", router.currentRoute.value.query.productCode?.toString()],
      ["subscriberCode", router.currentRoute.value.query.subscriberCode?.toString()],
    ]);
    params.forEach((v, k) => {
      if (v === undefined || v === null || Array.isArray(v)) {
        params.set(k, "");
      }
    });

    return {
      emailAddress: ref(""),
      password: ref(""),
      customerId: ref(params.get("customerId") ?? ""),
      productCode: ref(params.get("productCode") ?? ""),
      subscriberCode: ref(params.get("subscriberCode") ?? ""),
    };
  },
  methods: {
    cancel(): void {
      this.$store.dispatch("clearModal");
    },
    error(key: string) {
      return this.$store.getters.error(key);
    },
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    forgotPassword(): void {
      this.$store.dispatch("setModal", "VForgotPassword");
    },
    async signIn(): Promise<void> {
      const data = new SignInRequest({
        emailAddress: this.emailAddress,
        password: this.password,
        customerId: this.customerId,
        productCode: this.productCode,
        subscriberCode: this.subscriberCode,
      });

      if (!data.validate(this.$store)) {
        return;
      }

      await this.$store.dispatch("signIn", data);
    },
  },
});
