
import { defineComponent, ref } from "@vue/runtime-core";
import { SignUp_CustomerRequest } from "@/types";
import VInput from "@/components/form/VInput.vue";
import VDropdown from "@/components/form/VDropdown.vue";
import { DropdownLists } from "@/resources";


export default defineComponent({
  name: "VSignUpCustomer",
  components: {
    VInput,
    VDropdown,
  },
  setup() {
    return {
      role: ref(""),
      organisation: ref(""),
      industry: ref(""),
      sector: ref(""),
      introduction: ref(""),
    };
  },
  methods: {
    getCustomerInfo():void{
      this.$emit("update:sector", this.sector);
      this.$emit("update:industry", this.industry);
      this.$emit("update:role", this.role);
      this.$emit("update:organisation", this.organisation);
      this.$emit("update:introduction", this.introduction);

      // console.log(" log inside v ... customer")
      // console.log("organisation " , this.organisation)
      // console.log("role " , this.role)
      // console.log("sector " , this.sector)
      // console.log("industry " , this.industry)
    }, 
    importDropdowns():void {
        this.roleList = DropdownLists.jsonRoles();
        this.sectorList = DropdownLists.jsonSectors();
    },
    cancel(): void {
      this.$store.dispatch("clearModal");
    },
    error(key: string) {
      return this.$store.getters.error(key);
    },
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    // signIn(): void {
    //   this.$store.dispatch("setModal", "VSignIn");
    // },
  },
  data() {
    return {
      roleList: '{"optionList":[]}',
      sectorList: '{"optionList":[]}'
    }
  },
  created(){
    this.importDropdowns();
  }
});
