import { State } from "@/store";
import { Store } from "vuex";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

export interface IForgotPasswordConfirmRequest {
  readonly verificationCode: string;
  readonly newPassword: string;
}

export interface IForgotPasswordConfirmResponse {
  readonly CodeDeliveryDetails: AmazonCognitoIdentity.CodeDeliveryDetails;
}

export class ForgotPasswordConfirmRequest implements IForgotPasswordConfirmRequest {
  public readonly verificationCode: string;
  public readonly newPassword: string;

  public constructor(data: IForgotPasswordConfirmRequest) {
    this.verificationCode = data.verificationCode;
    this.newPassword = data.newPassword;
  }

  public validate(store: Store<State>): boolean {
    store.dispatch("clearErrors", ["verificationCode", "newPassword"]);

    const errors = new Map<string, string>();

    if (this.verificationCode === "") {
      errors.set("verificationCode", "A verification code is required");
    }

    if (this.newPassword === "") {
      errors.set("password", "A password is required");
    }

    if (this.newPassword.length < 16) {
      errors.set("password", "Password must be at least 16 characters long.");
    }

    store.dispatch("pushErrors", errors);

    return errors.size === 0;
  }
}
