
import { defineComponent, ref } from "@vue/runtime-core";
import { SignUpConfirmRequest, SignUp_CustomerRequest, SignUpRequest } from "@/types";
import VInput from "@/components/form/VInput.vue";
import VSignUpCustomer from "@/components/auth/VSignUp_Customer.vue";

import VSubmit from "@/components/form/VSubmit.vue";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

export default defineComponent({
  name: "VSignUpConfirm",
  props: {
    modalData: {
      type: null, // null = any type
      required: false,
    },
  },
  components: {
    VInput,
    VSubmit,
    VSignUpCustomer,
  },
  setup() {
    return {
      verificationCode: ref(""),
      role: ref(""),
      organisation: ref(""),
      industry: ref(""),
      sector: ref(""),
      introduction: ref(""),
      inputName: ref(""),
    };
  },
  methods: {
    cancel(): void {
      this.$store.dispatch("clearModal");
    },
    error(key: string) {
      return this.$store.getters.error(key);
    },
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    async signUpConfirm(): Promise<void> {
      (this.$refs.customerInfo as any).getCustomerInfo();

      const customerData = new SignUp_CustomerRequest({
        organisation: this.organisation,
        role: this.role,
        industry: this.industry,
        sector: this.sector,
        introduction: this.introduction,
      });
      const signUpData = this.modalData ? this.modalData : {Username: "", Password: "", Name: ""};
      const data = new SignUpConfirmRequest({
        verificationCode: this.verificationCode,
        customerData: customerData,
        signUpData: new SignUpRequest({
            name: (this.modalData.Name)?(this.modalData.Name):this.inputName,
            emailAddress: this.modalData.Username,
            password: this.modalData.Password,
        }),
      });

      if (!data.validate(this.$store)) {
        return;
      }

      await this.$store.dispatch("signUpConfirm", data);
    },
  },
});
