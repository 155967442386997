
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "VModal",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close(): void {
      this.$store.dispatch("clearModal");
    },
  },
});
