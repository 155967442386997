
import { defineComponent, ref } from "@vue/runtime-core";
import { ForgotPasswordConfirmRequest } from "@/types";
import VInput from "@/components/form/VInput.vue";
import VSubmit from "@/components/form/VSubmit.vue";

export default defineComponent({
  name: "VForgotPasswordConfirm",
  components: {
    VInput,
    VSubmit,
  },
  setup() {
    return {
      verificationCode: ref(""),
      newPassword: ref(""),
    };
  },
  methods: {
    cancel(): void {
      this.$store.dispatch("clearModal");
    },
    error(key: string) {
      return this.$store.getters.error(key);
    },
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    async forgotPasswordConfirm(): Promise<void> {
      const data = new ForgotPasswordConfirmRequest({
        verificationCode: this.verificationCode,
        newPassword: this.newPassword,
      });

      if (!data.validate(this.$store)) {
        return;
      }

      await this.$store.dispatch("forgotPasswordConfirm", data);
    },
  },
});
