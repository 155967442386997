import { State } from "@/store";
import { Store } from "vuex";

export interface ISignUp_CustomerRequest {
  readonly organisation: string;
  readonly role: string;
  readonly industry: string;
  readonly sector: string;
  readonly introduction: string;
}


// Your Organisation?
// Your role within the Organisation? (Research, Administration, Management, Technician, others)
// Your Organisation's Industry?
// How did you learn about QRNG?
// Is your organisation in the public or private sector? (Public, private)


export class SignUp_CustomerRequest implements ISignUp_CustomerRequest {
  readonly organisation: string;
  readonly role: string;
  readonly industry: string;
  readonly sector: string;
  readonly introduction: string;

  public constructor(data: ISignUp_CustomerRequest) {
    this.organisation = data.organisation;
    this.role = data.role;
    this.industry = data.industry;
    this.sector = data.sector;
    this.introduction = data.introduction; 
  }

  public validate(store: Store<State>): boolean {
    store.dispatch("clearErrors", ["organisation", "role", "industry", "sector", "introduction"]);
    const errors = new Map<string, string>();

    // Reinstate if they are required fields
    // if (this.company === "") {
    //   errors.set("company", "A company is required");
    // }
    // if (this.occupation === "") {
    //   errors.set("occupation", "Please provide your occupation");
    // }
    // if (this.sector === "") {
    //   errors.set("sector", "Please provide your sector");
    // }
    // if (this.industry === "") {
    //   errors.set("industry", "Please provide your industry");
    // }

    store.dispatch("pushErrors", errors);

    return errors.size === 0;
  }
}
