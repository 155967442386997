import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

export class Routes {
  // Publicly accessible routes.
  public static readonly HOME = "/";
  public static readonly ABOUT_US = "/about-us";
  public static readonly CONTACT_US = "/contact-us";
  public static readonly DOCUMENTATION = "/documentation";
  public static readonly FAQ = "/faq";
  public static readonly LICENSES = "/licenses";
  public static readonly PRICING = "/pricing";
  public static readonly SUBSCRIBE = "/subscribe/:id";

  // Private routes (requires authentication).
  public static readonly LIST_API_KEYS = "/api-key";
  public static readonly VIEW_PROFILE = "/profile";

  public static allPublic(): string[] {
    return [Routes.HOME, Routes.ABOUT_US, Routes.CONTACT_US, Routes.DOCUMENTATION, Routes.FAQ, Routes.LICENSES, Routes.PRICING, Routes.SUBSCRIBE];
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: Routes.HOME,
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: Routes.ABOUT_US,
    name: "AboutUs",
    component: () => import("@/views/AboutUs.vue"),
  },
  {
    path: Routes.LIST_API_KEYS,
    name: "ListApiKeys",
    component: () => import("@/components/api-keys/VListApiKeys.vue"),
  },
  {
    path: Routes.CONTACT_US,
    name: "ContactUs",
    component: () => import("@/views/ContactUs.vue"),
  },
  {
    path: Routes.DOCUMENTATION,
    name: "Documentation",
    component: () => import("@/views/Documentation.vue"),
  },
  {
    path: Routes.FAQ,
    name: "FAQ",
    component: () => import("@/views/FrequentlyAskedQuestions.vue"),
  },
  {
    path: Routes.LICENSES,
    name: "Licenses",
    component: () => import("@/views/Licenses.vue"),
  },
  {
    path: Routes.PRICING,
    name: "Pricing",
    component: () => import("@/views/Pricing.vue"),
  },
  {
    path: Routes.VIEW_PROFILE,
    name: "profile",
    component: () => import("@/views/profile/ViewProfile.vue"),
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: () => import("@/views/Subscribe.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
