
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "VInput",
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  methods: {
    input(event: InputEvent): void {
      this.$emit("update:value", (event.target as HTMLInputElement).value);
    },
  },
});
