import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = ["value", "disabled"]
const _hoisted_5 = ["type", "value", "disabled", "placeholder"]
const _hoisted_6 = {
  key: 2,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.type === 'textarea')
        ? (_openBlock(), _createElementBlock("textarea", {
            key: 0,
            class: _normalizeClass(["textarea", { 'is-danger': _ctx.error !== '' }]),
            value: _ctx.value,
            disabled: _ctx.disabled,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args)))
          }, null, 42, _hoisted_4))
        : (_openBlock(), _createElementBlock("input", {
            key: 1,
            class: _normalizeClass(["input", { 'is-danger': _ctx.error !== '' }]),
            type: _ctx.type,
            value: _ctx.value,
            disabled: _ctx.disabled,
            onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
            placeholder: _ctx.placeholder
          }, null, 42, _hoisted_5)),
      (_ctx.error !== undefined)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}