import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as fas from "@fortawesome/free-solid-svg-icons";
import { VueReCaptcha } from "vue-recaptcha-v3";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store, { key } from "@/store";

library.add(fas.faCertificate, fas.faChevronDown, fas.faSignOutAlt, fas.faUser);

import "@/assets/scss/app.scss";

createApp(App)
  .use(store, key)
  .use(router)
  .use(VueReCaptcha, {
    siteKey: "6Lfup8gcAAAAALaKXigzu7_SFyRk9WiFFN03nYmk",
  })
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
