
import { useStore } from "@/store";
import { computed, defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
  name: "VNavBar",
  setup() {
    const store = useStore();

    return {
      isActive: ref(false),
      isSignedIn: ref(computed(() => store.state.session.isValid())),
    };
  },
  methods: {
    setModal(componentName: string): void {
      this.$store.dispatch("setModal", componentName);
    },
    selectItem(path: string): void {
      this.isActive = false;
      this.$router.push(path);
    },
    async signOut(): Promise<void> {
      this.$store.dispatch("signOut");
    },
  },
});
