
import { computed, defineComponent, ref } from "@vue/runtime-core";
import { useStore } from "@/store";
import VViewApiKey from "@/components/api-keys/VViewApiKey.vue";
import VChangePassword from "@/components/auth/VChangePassword.vue";
import VForgotPassword from "@/components/auth/VForgotPassword.vue";
import VForgotPasswordConfirm from "@/components/auth/VForgotPasswordConfirm.vue";
import VSignIn from "@/components/auth/VSignIn.vue";
import VSignUp from "@/components/auth/VSignUp.vue";
import VSignUp_Eula from "@/components/auth/VSignUp_Eula.vue";
import VSignUp_Customer from "@/components/auth/VSignUp_Customer.vue";
import VSignUpConfirm from "@/components/auth/VSignUpConfirm.vue";
import VNavBar from "@/components/VNavBar.vue";
import VModal from "@/components/VModal.vue";
import VToast from "@/components/VToast.vue";

export default defineComponent({
  name: "App",
  components: {
    VViewApiKey,
    VChangePassword,
    VForgotPassword,
    VForgotPasswordConfirm,
    VSignIn,
    VSignUp,
    VSignUp_Eula,
    VSignUp_Customer,
    VSignUpConfirm,
    VNavBar,
    VModal,
    VToast,
  },
  setup() {
    const store = useStore();

    return {
      loading: ref(computed(() => store.state.loading.size !== 0)),
      modalContent: ref(computed(() => store.state.modalContent)),
      modalData: ref(computed(() => store.state.modalData)),
    };
  },
  beforeCreate() {
    this.$store.dispatch("initializeState");
  },
});
