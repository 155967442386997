import { State } from "@/store";
import { Store } from "vuex";

export interface ISignUpRequest {
  readonly name: string;
  readonly emailAddress: string;
  readonly password: string;
}

export class SignUpRequest implements ISignUpRequest {
  public readonly name: string;
  public readonly emailAddress: string;
  public readonly password: string;

  public constructor(data: ISignUpRequest) {
    this.name = data.name;
    this.emailAddress = data.emailAddress;
    this.password = data.password;
  }

  public validate(store: Store<State>): boolean {
    store.dispatch("clearErrors", ["name", "emailAddress", "password", "passwordConfirmation"]);

    const errors = new Map<string, string>();

    if (this.name === "") {
      errors.set("name", "Your name is required");
    }

    if (this.emailAddress === "") {
      errors.set("emailAddress", "An email address is required");
    }

    if (this.password === "") {
      errors.set("password", "A password is required");
    }

    if (this.password.length < 16) {
      errors.set("password", "Password must be at least 16 characters long.");
    }

    store.dispatch("pushErrors", errors);

    return errors.size === 0;
  }
}
