import { AwsException } from ".";

export class Strings {
  public static confirmDelete(object: string): string {
    return `Are you sure you want to delete this ${object}?`;
  }

  public static error(): string {
    return "Oh no, something went wrong! If this persists please contact support.";
  }

  public static exception(exception: string): string {
    switch (exception) {
      case AwsException.INVALID_PARAMETER_EXCEPTION:
        return "Invalid email address or password, email address must be valid and password must be at least 16 characters long.";
      case AwsException.LIMIT_EXCEEDED_EXCEPTION:
        return "Attempt limit exceeded, please try after some time.";
      case AwsException.USERNAME_EXISTS_EXCEPTION:
        return "A user with that email address already exists.";
      case AwsException.USER_NOT_CONFIRMED_EXCEPTION:
        return "Your account has not been confirmed.";
      case AwsException.USER_NOT_FOUND_EXCEPTION:
        return "An account with that email address does not exist.";
      case AwsException.NOT_AUTHORIZED_EXCEPTION:
        return "Incorrect email address or password.";
      default:
        return Strings.error();
    }
  }

  public static failedToComplete(object: string): string {
    return `Failed to complete ${object}.`;
  }

  public static failedToCreate(object: string): string {
    return `Failed to create ${object}.`;
  }

  public static failedToDelete(object: string): string {
    return `Failed to delete ${object}.`;
  }

  public static failedToFetch(object: string): string {
    return `Failed to fetch ${object}.`;
  }

  public static unauthorized(): string {
    return "You are not authorized to do that.";
  }

  public static verificationCode(destination: string): string {
    return `A verification code has been sent to ${destination}.`;
  }

  public static verificationCodeExpired(destination: string): string {
    return `Verification code expired, a new one has been sent to ${destination}.`;
  }
}
