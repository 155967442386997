
export class EndUserLicenceAgreement {
    public static EndUserLicenceAgreement(): string { // return in html
        const EulaText = " \
            <ol>\
                <li class='eulaHeader'>  Agreement, Acceptance and Changes\
                    <ol class='eulaText'> <li> By registering an account and using or accessing the Service, you agree to abide by these terms (Agreement), as we may amend from time to time. </li> \
                        <li> We grant you a limited, non-exclusive, non-transferable licence to use the Service for any purpose other than what is strictly prohibited under clause 3.1, subject to the terms of this Agreement. </li> \
                        <li> We will notify you of any changes to this Agreement, either by email to the email address associated with your account, or by notification on the login screen on the website. </li> \
                        <li> Any changes to this Agreement automatically bind you upon you logging in to your account. </li> \
                    </ol>\
                </li>\
                <li class='eulaHeader'> Protecting Your Privacy\
                    <ol class='eulaText'>\
                        <li> Any personal information that is required for you to create an account or continue to use the Service will be handled in accordance with ANU’s Privacy Statement. </li>\
                        <li> Please review the Privacy Statement to understand how the ANU collects and uses the information associated with your account.\
                        <a href='https://policies.anu.edu.au/ppl/document/ANUP_010007'>link https://policies.anu.edu.au/ppl/document/ANUP_010007;</a>\
                        <a href='http://www.anu.edu.au/privacy'>http://www.anu.edu.au/privacy</a>.\
                        </li>\
                        <li> We will never sell your personal information to anybody. </li>\
                    </ol>\
                </li>\
                <li class='eulaHeader'> Code of Conduct\
                    <ol class='eulaText'>\
                        <li> In using the Service you must:\
                            <ol type='a'>\
                                <li>Not access or use the Service for any unlawful or illegal use or purpose; </li>\
                                <li>Not access (or attempt to access) the Service by any means other than through your username, password and API key(s) for your account; </li>\
                                <li>Not share your password with others or otherwise disclose your password to unauthorised third parties for any purpose whatsoever. </li>\
                                <li>Not use the Service in any way that could damage the reputation of the ANU;  </li>\
                                <li>Not breach any applicable ANU policies or guidelines. </li>\
                            </ol>\
                        </li>\
                    </ol>\
                </li>\
                <li class='eulaHeader'> Deletion, Suspension or Termination of Your Account\
                    <ol class='eulaText'> \
                        <li> You may delete your account at any time, where “delete” means voluntarily ending your access to your account. After you delete your account, you may create a new account, but it will never be possible to reactivate your old account, as the deletion will be permanent.</li>\
                        <li> We may suspend or end your access to your account if we reasonably believe you have violated this Agreement.</li>\
                        <li> If we consider, acting reasonably, that you have violated this Agreement, we may take any of the following actions (without limitation):</li>\
                        <ol>\
                        <li> Ask you to refrain from certain activities; </li>\
                        <li> Immediately suspend or terminate all or any part of your account; </li>\
                        <li> Gathering information about your use of the Service;</li>\
                        <li> Investigate any suspected violation of this Agreement; and</li>\
                        <li> Cooperate with law enforcement authorities in the investigation of suspected criminal violations.</li>\
                        </ol>\
                        <li> If we suspend or end your account, your licence to use the Service suspends or ends (as relevant) and we will deactivate your login access unless and until we decide, in our absolute discretion, to re-activate your account.</li>\
                        </ol>\
                </li>\
                <li class='eulaHeader'> General\
                    <ol class='eulaText'> \
                    <li> General Acknowledgement: You acknowledge that no system is fault free and occasional periods of downtime occur. The ANU cannot guarantee that the Service will be uninterrupted, timely or error-free.</li>\
                    <li> Service Disruptions: We do not warrant the Service will be available all the time or at any particular time.</li>\
                    <li> Limited Warranties: the ANU and its subcontractors make no warranties (express or implied), guarantees or conditions, with respect to your use of the Service or its performance except that: </li>\
                    <ol type='a'>\
                    <li> numbers generated are ‘truly random’ to the extent that the randomness tests we agree to regularly perform indicate that the numbers are so; and </li>\
                    <li> neither the ANU nor any of its subcontractors will tamper with any aspect of the Service whatsoever during testing or at any time.</li>\
                    </ol>\
                    <li>Limitation of Statutory Warranties: to the extent permitted by law, the ANU excludes any implied warranties, including for merchantability, satisfactory quality, fitness for a particular purpose, work-man-like effort and non-infringement. You may have certain rights under law and nothing in this Agreement is intended to affect those rights, if they are applicable.</li>\
                    <li>Limitation of Liability: to the full extent permitted by law, the ANU accepts no liability for any loss or damage of any kind (including any indirect or consequential loss) you may suffer in connection with your use of, or inability to use, the Service. </li>\
                    <li>Governing Law: The laws of the Australian Capital Territory govern the interpretation of this Agreement, claims for breach of it and all other claims regardless of conflict of law principles. You and we (the ANU) irrevocably consent to the exclusive jurisdiction and vent of the Australian Capital Territory or Commonwealth courts (as applicable) for all disputes arising out of or relating to this Agreement.</li>\
                    <li>Assignment and Novation by You: You may not assign, transfer, novate or otherwise deal with in any way your rights, benefits or obligations under this Agreement in whole or in part to any third party.</li>\
                    <li>Assignment and Novation by Us: You agree that the ANU is entitled to assign any or all of the ANU’s rights, benefits and obligations of this Agreement or novate this Agreement to any entity, body or nominated subcontractor of the ANU. In the event of any assignment or changes to the Agreement or if the ANU novates its rights, benefits, obligations and liabilities to a third party, you will be notified next time you go to login to your account.</li>\
                    <li>Automatic Acceptance of Novation: By accepting the new Agreement when you next login, you will have expressly consented to: the release of the ANU from all of its rights, obligations and liabilities under the Agreement; the discharge of the existing Agreement; and the substitution of a third party who will assume all of the rights, obligations and liabilities under the Agreement.</li>\
                    <li>Notices: You consent to us contacting you in relation to your use of your TRNs by email to the nominated email address attached to your account. </li>\
                    <li>Contract Interpretation: This agreement is the entire agreement between you and the ANU relating to the Service. It supersedes any prior agreement regarding your use of the Service. All parts of this Agreement apply to the maximum extent permitted by relevant law. The Agreement’s section titles are for reference only and have no legal effect.</li>\
                    <li>Severance: If any part of this Agreement is void or unenforceable, that part is severed without affecting the enforceability of the remaining terms, and the ANU may replace the severed terms with similar terns to the extend enforces void ore under the law, but the rest of this Agreement will not change.</li>\
                </ol>\
            </li>\
        </ol>\
        "
      return EulaText;
    }
}
