
import { defineComponent, ref } from "@vue/runtime-core";
import { ChangePasswordRequest } from "@/types";
import VInput from "@/components/form/VInput.vue";
import VSubmit from "@/components/form/VSubmit.vue";

export default defineComponent({
  name: "VChangePassword",
  components: {
    VInput,
    VSubmit,
  },
  setup() {
    return {
      oldPassword: ref(""),
      newPassword: ref(""),
    };
  },
  methods: {
    cancel(): void {
      this.$store.dispatch("clearModal");
    },
    error(key: string) {
      return this.$store.getters.error(key);
    },
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    async changePassword(): Promise<void> {
      const data = new ChangePasswordRequest({
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      });

      if (!data.validate(this.$store)) {
        return;
      }

      await this.$store.dispatch("changePassword", data);
    },
  },
});
