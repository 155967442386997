
import { defineComponent, ref } from "@vue/runtime-core";
import { SignUp_EulaRequest } from "@/types";
import VInput from "@/components/form/VInput.vue";
import VSubmit from "@/components/form/VSubmit.vue";
import { EndUserLicenceAgreement } from "@/resources";


export default defineComponent({
  name: "VSignUp_Eula",
  components: {
    VInput,
    VSubmit,
  },
  setup() {
    return {
      agreement: ref(""),
    };
  },
  methods: {
    cancel(): void {
      this.$store.dispatch("clearModal");
    },
    error(key: string) {
      return this.$store.getters.error(key);
    },
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    signIn(): void {
      this.$store.dispatch("setModal", "VSignIn");
    },
    async signUp_Eula(): Promise<void> {
      const data = new SignUp_EulaRequest({
        // convert to bool if mtches case-insensitive agree
        b_eula: this.agreement.toLowerCase() == "agree",
      });

      if (!data.validate(this.$store)) {
        return;
      }

      await this.$store.dispatch("signUp_Eula", data);
    },
    importEula():void {
      this.eula = EndUserLicenceAgreement.EndUserLicenceAgreement();
      this.$el.querySelector('#eulaText')!.innerHTML = this.eula;
    }

  },
  data() {
    return {
      eula: '',
    }
  },
  mounted(){
    this.importEula();
  }
});
