import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
// import * as AmazonCognito from 'amazon-cognito-js'
import { Api } from "@/api";
import { AwsException, Strings } from "@/resources";
import router, { Routes } from "@/router";
import * as types from "@/types";
import { Toast, ToastType } from "@/types";

export interface State {
  apiKey: types.IApiKey;
  apiKeys: types.IApiKey[];
  errors: Map<string, string>;
  loading: Map<string, boolean>;
  modalContent: string;
  modalData: string;
  session: AmazonCognitoIdentity.CognitoUserSession;
  toastClearTimeout: number;
  toastFadeTimeout: number;
  toast: Toast;
  user: AmazonCognitoIdentity.CognitoUser | null;
  userPool: AmazonCognitoIdentity.CognitoUserPool;
}

export const key: InjectionKey<Store<State>> = Symbol();

export function useStore(): Store<State> {
  return baseUseStore(key);
}

export default createStore<State>({
  state: {
    apiKey: types.newApiKey(),
    apiKeys: [],
    errors: new Map(),
    loading: new Map(),
    modalContent: "",
    modalData: "",
    session: new AmazonCognitoIdentity.CognitoUserSession({
      AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: "",
      }),
      IdToken: new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: "",
      }),
    }),
    toastClearTimeout: -1,
    toastFadeTimeout: -1,
    toast: {
      message: "",
      type: ToastType.PRIMARY,
    },
    user: null,
    userPool: new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.VUE_APP_USER_POOL_ID,
      ClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
    }),
  },
  getters: {
    error(state: State): (key: string) => string {
      return (key: string) => {
        return state.errors.get(key) ?? "";
      };
    },
    loading(state: State): (key: string) => boolean {
      return (key: string) => {
        return state.loading.get(key) ?? false;
      };
    },
  },
  mutations: {
    setApiKey(state: State, apiKey: types.IApiKey): void {
      state.apiKey = apiKey;
    },
    setApiKeys(state: State, apiKeys: types.IApiKey[]): void {
      state.apiKeys = apiKeys;
    },
    setError(state: State, { key, value }): void {
      state.errors.set(key, value);
    },
    setLoading(state: State, { key, value }): void {
      if (value === false) {
        state.loading.delete(key);
        return;
      }

      state.loading.set(key, value);
    },
    setModal(state: State, componentName: string): void {
      state.modalContent = componentName;
      state.modalData = "";
    },
    setModal_data(state: State, packet: { componentName: string; data: any }): void {
      state.modalData = packet.data;
      state.modalContent = packet.componentName;
    },
    setSession(state: State, session: AmazonCognitoIdentity.CognitoUserSession): void {
      state.session = session;
    },
    setToast(state: State, toast: Toast): void {
      state.toast = toast;
    },
    setUser(state: State, user: AmazonCognitoIdentity.CognitoUser): void {
      state.user = user;
    },
    resetState(state: State): void {
      state = {
        apiKey: types.newApiKey(),
        apiKeys: [],
        errors: new Map(),
        loading: new Map(),
        modalContent: "",
        modalData: "",
        session: new AmazonCognitoIdentity.CognitoUserSession({
          AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({
            AccessToken: "",
          }),
          IdToken: new AmazonCognitoIdentity.CognitoIdToken({
            IdToken: "",
          }),
        }),
        toastClearTimeout: -1,
        toastFadeTimeout: -1,
        toast: {
          message: "",
          type: ToastType.PRIMARY,
        },
        user: null,
        userPool: new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId: process.env.VUE_APP_USER_POOL_ID,
          ClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
        }),
      };
    },
  },
  actions: {
    async changePassword(context, data: types.IChangePasswordRequest): Promise<void> {
      context.dispatch("startLoading", "changePassword");

      if (context.state.user === null) {
        console.error("Failed to change password: user is null");
        Toast.error(this, Strings.error());
        context.dispatch("finishLoading", "changePassword");
        return;
      }

      context.state.user.changePassword(data.oldPassword, data.newPassword, (error?: Error, result?: string) => {
        if (error !== null && error !== undefined) {
          console.error(error);
          Toast.error(this, Strings.error());
          context.dispatch("finishLoading", "changePassword");
          return;
        }

        console.log(result);
        Toast.success(this, "Change password successful.");
        context.dispatch("clearModal");
      });

      context.dispatch("finishLoading", "changePassword");
    },
    async contactUs(context, data: types.ContactUsRequest): Promise<void> {
      context.dispatch("startLoading", "contactUs");

      try {
        const response = await Api.contactUs(this, data);
        if (!Api.checkStatus(this, response.status, "Contact submission failed.")) {
          context.dispatch("finishLoading", "contactUs");
          return;
        }

        Toast.success(this, "Contact submission successful.");
      } catch (error) {
        console.error(error);
        Toast.error(this, Strings.error());
      }

      context.dispatch("finishLoading", "contactUs");
    },
    async createApiKey(context): Promise<void> {
      context.dispatch("startLoading", "createApiKey");
      context.commit("setApiKey", types.newApiKey());

      try {
        const response = await Api.putApiKey(this);
        if (!Api.checkStatus(this, response.status, Strings.failedToCreate("API key"))) {
          context.dispatch("finishLoading", "createApiKey");
          return;
        }

        context.commit("setApiKey", response.data);
        context.dispatch("getApiKeys");
      } catch (error) {
        console.error(error);
        Toast.error(this, Strings.error());
      }

      context.dispatch("finishLoading", "createApiKey");
    },
    clearErrors(context, keys: string[]): void {
      keys.forEach((k) => context.commit("setError", { key: k, value: "" }));
    },
    clearModal(context): void {
      context.commit("setModal", "");
    },
    async deleteApiKey(context, apiKeyId: string): Promise<void> {
      context.dispatch("startLoading", "deleteApiKey");
      context.commit("setApiKey", types.newApiKey());

      try {
        const response = await Api.deleteApiKey(this, apiKeyId);
        if (!Api.checkStatus(this, response.status, Strings.failedToDelete("API key"))) {
          context.dispatch("finishLoading", "deleteApiKey");
          return;
        }

        context.dispatch("getApiKeys");
      } catch (error) {
        console.error(error);
        Toast.error(this, Strings.error());
      }

      context.dispatch("finishLoading", "deleteApiKey");
    },
    finishLoading(context, key): void {
      context.commit("setLoading", { key: key, value: false });
    },
    async getApiKeys(context): Promise<void> {
      context.dispatch("startLoading", "getApiKeys");

      try {
        const response = await Api.getApiKeys(this);
        if (!Api.checkStatus(this, response.status, Strings.failedToFetch("API keys"))) {
          context.dispatch("finishLoading", "getContacts");
          return;
        }

        context.commit("setApiKeys", response.data);
      } catch (error) {
        console.error(error);
        Toast.error(this, Strings.error());
      }

      context.dispatch("finishLoading", "getApiKeys");
    },
    initializeState(context): void {
      const user = context.state.userPool.getCurrentUser();
      if (user === null) {
        for (const index in Routes.allPublic()) {
          if (Routes.allPublic()[index] === router.currentRoute.value.fullPath) {
            return;
          }
        }

        Toast.error(this, Strings.unauthorized());
        context.dispatch("signOut");
        return;
      }

      user.getSession((error: Error | null, session: AmazonCognitoIdentity.CognitoUserSession | null) => {
        if (error !== null || session === null) {
          console.error(`Failed to fetch user session: ${error}`);
          return;
        }

        context.commit("setSession", session);
      });

      context.commit("setUser", user);
    },
    setModal(context, componentName: string): void {
      context.commit("setModal", componentName);
    },
    setModal_data(context, packet: { componentName: string; data: any }): void {
      context.commit("setModal_data", packet);
    },
    pushErrors(context, errors: Map<string, string>): void {
      errors.forEach((v, k) => context.commit("setError", { key: k, value: v }));
    },
    setToast(context, toast: Toast): void {
      context.commit("setToast", toast);

      clearTimeout(context.state.toastFadeTimeout);
      clearTimeout(context.state.toastClearTimeout);

      context.state.toastFadeTimeout = setTimeout(() => {
        context.state.toastFadeTimeout = -1;
      }, 3000);
      context.state.toastClearTimeout = setTimeout(() => {
        context.state.toastClearTimeout = -1;
      }, 3500);
    },
    async forgotPassword(context, data: types.IForgotPasswordRequest): Promise<void> {
      context.dispatch("startLoading", "forgotPassword");

      const user = new AmazonCognitoIdentity.CognitoUser({
        Username: data.emailAddress,
        Pool: context.state.userPool,
      });

      user.forgotPassword({
        onSuccess: (data: types.IForgotPasswordConfirmResponse) => {
          Toast.info(this, Strings.verificationCode(data.CodeDeliveryDetails.Destination));
          context.commit("setUser", user);
          context.dispatch("setModal", "VForgotPasswordConfirm");
        },
        onFailure: (error) => {
          switch (error.name) {
            case AwsException.LIMIT_EXCEEDED_EXCEPTION:
            case AwsException.USER_NOT_FOUND_EXCEPTION:
              Toast.error(this, Strings.exception(error.name));
              break;
            default:
              console.error(error);
              Toast.error(this, Strings.error());
              break;
          }
        },
      });

      context.dispatch("finishLoading", "forgotPassword");
    },
    async forgotPasswordConfirm(context, data: types.IForgotPasswordConfirmRequest): Promise<void> {
      context.dispatch("startLoading", "forgotPasswordConfirm");

      if (context.state.user === null) {
        console.error("Failed to confirm forgot password: user is null");
        Toast.error(this, Strings.error());
        context.dispatch("finishLoading", "forgotPasswordConfirm");
        return;
      }

      context.state.user.confirmPassword(data.verificationCode, data.newPassword, {
        onSuccess: () => {
          Toast.success(this, "Password reset was successful.");
          context.dispatch("setModal", "VSignIn");
        },
        onFailure: (error: Error) => {
          console.error(error);
          Toast.error(this, Strings.error());
          context.dispatch("finishLoading", "forgotPasswordConfirm");
        },
      });

      context.dispatch("finishLoading", "forgotPasswordConfirm");
    },
    setApiKey(context, apiKey: types.IApiKey): void {
      context.commit("setApiKey", apiKey);
    },
    async signIn(context, data: types.ISignInRequest): Promise<void> {
      context.dispatch("startLoading", "signIn");

      const user = new AmazonCognitoIdentity.CognitoUser({
        Username: data.emailAddress,
        Pool: context.state.userPool,
      });
      user.setAuthenticationFlowType("USER_PASSWORD_AUTH");

      user.authenticateUser(
        new AmazonCognitoIdentity.AuthenticationDetails({
          Username: data.emailAddress,
          Password: data.password,
        }),
        {
          onSuccess: (session) => {
            context.commit("setSession", session);
            context.commit("setUser", user);
            context.dispatch("clearModal");

            if (data.customerId !== "" && data.productCode !== "") {
              context.dispatch("subscribeConfirm", {
                customerId: data.customerId,
                productCode: data.productCode,
                subscriberCode: data.subscriberCode,
              });
            }
          },
          onFailure: (error) => {
            const passData = {
              Username: data.emailAddress,
              Password: data.password,
              Name: "",
            };
            switch (error.name) {
              case AwsException.USER_NOT_FOUND_EXCEPTION:
              case AwsException.NOT_AUTHORIZED_EXCEPTION:
                Toast.error(this, Strings.exception(error.name));
                break;
              case AwsException.USER_NOT_CONFIRMED_EXCEPTION:
                context.commit("setUser", user);
                Toast.info(this, Strings.exception(error.name));
                context.dispatch("finishLoading", "signIn");
                context.dispatch("setModal_data", { componentName: "VSignUpConfirm", data: passData });
                break;
              default:
                Toast.error(this, Strings.error());
                break;
            }
          },
        }
      );

      context.dispatch("finishLoading", "signIn");
    },
    signOut(context): void {
      context.state.user?.signOut();
      context.commit(
        "setSession",
        new AmazonCognitoIdentity.CognitoUserSession({
          AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({
            AccessToken: "",
          }),
          IdToken: new AmazonCognitoIdentity.CognitoIdToken({
            IdToken: "",
          }),
        })
      );
      context.commit("resetState");
      router.push(Routes.HOME);
    },

    async signUp_Eula(context, data: types.ISignUp_EulaRequest): Promise<void> {
      context.dispatch("startLoading", "signUp_Eula");

      context.commit("setModal", "VSignUp");
      context.dispatch("finishLoading", "signUp_Eula");
    },

    async signUp(context, data: types.ISignUpRequest): Promise<void> {
      context.dispatch("startLoading", "signUp");

      context.state.userPool.signUp(data.emailAddress, data.password, [], [], (error, result) => {
        if (error !== null) {
          switch (error?.name) {
            case AwsException.INVALID_PARAMETER_EXCEPTION:
              Toast.info(this, Strings.exception(error.name));
              break;
            case AwsException.USERNAME_EXISTS_EXCEPTION:
              Toast.info(this, Strings.exception(error.name));
              context.dispatch("setModal", "VSignIn");
              break;
            default:
              Toast.error(this, Strings.error());
              break;
          }
          context.dispatch("finishLoading", "signUp");
          return;
        }

        if (result === undefined) {
          console.error("Failed to sign up user: result is undefined");
          Toast.error(this, Strings.error());
          context.dispatch("finishLoading", "signUp");
          return;
        }

        Toast.info(this, Strings.verificationCode(result.codeDeliveryDetails.Destination));
        context.commit("setUser", result.user);
      });

      const passData = {
        Username: data.emailAddress,
        Password: data.password,
        Name: data.name,
      };
      // new AmazonCognitoIdentity.AuthenticationDetails({
      //   Username: data.emailAddress,
      //   Password: data.password,
      // })
      // send data through and move to VSignUpConfirm    
      context.dispatch("finishLoading", "signUp");
      context.dispatch("setModal_data", { componentName: "VSignUpConfirm", data: passData });
    },

    async signUpConfirm(context, data: types.ISignUpConfirmRequest): Promise<void> {
      context.dispatch("startLoading", "signUpConfirm");
      // check that there is still a user in scope 
      if (context.state.user === null) {
        console.error("Failed to confirm sign up: user is null");
        Toast.error(this, Strings.error());
        context.dispatch("finishLoading", "signUpConfirm");
        return;
      }

      if (!data.signUpData.password) {
        console.error("Tried ConfirmCode without auth details");
        Toast.error(this, "Missing user details, please login.");
        context.dispatch("finishLoading", "signUpConfirm");
        context.commit("setModal", "VSignIn");
      }

      // confirm rego with AWS
      context.state.user.confirmRegistration(data.verificationCode, true, (error, result) => {
        if (error !== null && error !== undefined) {
          console.log("confirmReg failure :: :")
          console.error(error);
          if (error?.name == AwsException.EXPIRED_CODE_EXCEPTION) {
            Toast.info(this, Strings.verificationCodeExpired(result.codeDeliveryDetails.Destination));
            context.state.user?.resendConfirmationCode((error, result) => {
              if (error !== null && error !== undefined) {
                console.error("resendConfirm failure:");
              }
            });
          } else {
            Toast.error(this, Strings.error());
          }
          context.dispatch("finishLoading", "signUpConfirm");
          return;
        }
        ///////////////////////////////////////////////////////
        context.state.user?.setAuthenticationFlowType("USER_PASSWORD_AUTH");
        context.state.user?.authenticateUser(
          new AmazonCognitoIdentity.AuthenticationDetails({
            Username: context.state.user.getUsername(),
            Password: data.signUpData.password,
          }),
          {
            onSuccess: async (session) => {
              context.commit("setSession", session);
              context.commit("setUser", context.state.user);
              context.dispatch("clearModal");
              ///////////////////////////////////////////////////////
              Toast.success(this, "Sign up successful.");
              ///////////////////////////////////////////////////////                    
              // And push the customer data out to the API.
              try {
                const response = await Api.webuserVerify(this, data.signUpData.name, data.customerData);
                // if (!Api.checkStatus(this, response.status, Strings.failedToComplete("webuserVerify"))) {
                //   // router.push(Routes.LIST_API_KEYS);
                //   // context.dispatch("finishLoading", "subscribeConfirm");
                //   // return;
                // }
                // Toast.success(this, "WebuserVerify  successful");
                context.dispatch("finishLoading", "signUpConfirm");
                router.push(Routes.LIST_API_KEYS);
              } catch (error) {
                console.error(error);
                Toast.error(this, Strings.error());
                context.dispatch("finishLoading", "signUpConfirm");
              }
              ///////////////////////////////////////////////////////
            },
            onFailure: (error) => {
              switch (error.name) {
                case AwsException.USER_NOT_FOUND_EXCEPTION:
                case AwsException.NOT_AUTHORIZED_EXCEPTION:
                  Toast.error(this, Strings.exception(error.name));
                  break;
                case AwsException.USER_NOT_CONFIRMED_EXCEPTION:
                  context.commit("setUser", context.state.user);
                  Toast.info(this, Strings.exception(error.name));
                  context.dispatch("setModal", "VSignUpConfirm");
                  break;
                default:
                  Toast.error(this, Strings.error());
                  break;
              }
              return;
            },
          }
        );
      });
    },

    startLoading(context, key): void {
      context.commit("setLoading", { key: key, value: true });
    },

    async promiseConfirmedUserAuthentication(context, data: types.SignInRequest): Promise<AmazonCognitoIdentity.CognitoUserSession> {
      return new Promise((resolve, reject) => {
        if (context.state.user === null) {
          console.error("Failed to sign in");
          Toast.error(this, Strings.error());
          reject(Strings.error());
        } else {
          context.state.user.authenticateUser(
            new AmazonCognitoIdentity.AuthenticationDetails({
              Username: data.emailAddress,
              Password: data.password,
            }), {
            onSuccess: (session) => { resolve(session); },
            onFailure: (error) => { reject(error); },
          }
          );
        }
      }
      );
    },

    async subscribeConfirm(context, data: types.ISubscribeConfirmRequest): Promise<void> {
      context.dispatch("startLoading", "subscribeConfirm");

      try {
        const response = await Api.subscribeConfirm(this, data);
        if (!Api.checkStatus(this, response.status, Strings.failedToComplete("subscription"))) {
          router.push(Routes.LIST_API_KEYS);
          context.dispatch("finishLoading", "subscribeConfirm");
          return;
        }

        Toast.success(this, "Subscription confirmation request made");
        router.push(Routes.LIST_API_KEYS);
      } catch (error) {
        console.error(error);
        Toast.error(this, Strings.error());
      }

      context.dispatch("finishLoading", "subscribeConfirm");
    },
  },
  modules: {},
});
